<script>
import appConfig from "@/app.config";
import IntakeType1 from "./tipe-jawaban/tipe1.vue";
import IntakeType2 from "./tipe-jawaban/tipe2.vue";
import IntakeType3 from "./tipe-jawaban/tipe3.vue";
import IntakeType4 from "./tipe-jawaban/tipe4.vue";
import IntakeType5 from "./tipe-jawaban/tipe5.vue";
import IntakeType6 from "./tipe-jawaban/tipe6.vue";
import IntakeType7Persiapan from "./tipe-jawaban/tipe7_persiapan.vue";
import IntakeType7Diskusi from "./tipe-jawaban/tipe7_diskusi.vue";
import IntakeType8Persiapan from "./tipe-jawaban/tipe8_persiapan.vue";
import IntakeType8Interaksi from "./tipe-jawaban/tipe8_interaksi.vue";
import IntakeType9Persiapan from "./tipe-jawaban/tipe9_persiapan.vue";
import IntakeType9Diskusi from "./tipe-jawaban/tipe9_diskusi.vue";
import Swal from "sweetalert2";
import axios from "axios";
/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Tambah Materi",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    IntakeType1,
    IntakeType2,
    IntakeType3,
    IntakeType4,
    IntakeType5,
    IntakeType6,
    IntakeType7Persiapan,
    IntakeType7Diskusi,
    IntakeType8Persiapan,
    IntakeType8Interaksi,
    IntakeType9Persiapan,
    IntakeType9Diskusi,
  },
  data() {
    return {
      tipe: this.$route.params.tipe,
      tipe_pengerjaan: this.$route.params.tipe_pengerjaan,
      tabFocus: true,
    };
  },
  mounted() {
    this.checkUserAccess();
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
  },
  beforeUnmount() {
    document.removeEventListener("visibilitychange", this.handleVisibilityChange);
  },
  methods: {
    async checkUserAccess() {
      var url = process.env.VUE_APP_BACKEND_URL_VERSION + "check-access-user";
      var config = {
        method: "get",
        url: url,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
        params: {
          id_jadwal_detail: this.$route.params.id_jadwal_detail,
          jadwal_id: this.$route.params.id_jadwal,
          nip: localStorage.session_username,
        },
      };
      axios(config)
        .then((response) => {
          let response_data = response.data.data;
          if (!response_data) {
            this.$router.push("/acces-denied");
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    // Method dari masing masing fungsi di dalam file ini
    disableRightClick(event) {
      // Prevent the right-click context menu
      event.preventDefault();
    },
    handleVisibilityChange() {
      if (document.hidden) {
        // User switched tabs or left the page
        console.log("Berpindah Halaman");
      } else {
        // User came back to the page
        Swal.fire({
          icon: "warning",
          title: "Oops...",
          text: "Anda melakukan kecurangan dengan berpindah dari halaman tes.",
        });
      }
    },
  },
};
</script>
<template>
  <div @contextmenu="disableRightClick">
    <div v-if="tipe == 'intaketype1' && tipe_pengerjaan == 'tes'">
      <IntakeType1 />
    </div>
    <div v-else-if="tipe == 'intaketype2' && tipe_pengerjaan == 'tes'">
      <IntakeType2 />
    </div>
    <div v-else-if="tipe == 'intaketype3' && tipe_pengerjaan == 'tes'">
      <IntakeType3 />
    </div>
    <div v-else-if="tipe == 'intaketype4' && tipe_pengerjaan == 'tes'">
      <IntakeType4 />
    </div>
    <div v-else-if="tipe == 'intaketype5' && tipe_pengerjaan == 'tes'">
      <IntakeType5 />
    </div>
    <div v-else-if="tipe == 'intaketype6' && tipe_pengerjaan == 'tes'">
      <IntakeType6 />
    </div>
    <div v-else-if="tipe == 'intaketype7' && tipe_pengerjaan == 'persiapan_lgd'">
      <IntakeType7Persiapan />
    </div>
    <div v-else-if="tipe == 'intaketype7' && tipe_pengerjaan == 'diskusi_lgd'">
      <IntakeType7Diskusi />
    </div>
    <div v-else-if="tipe == 'intaketype8' && tipe_pengerjaan == 'persiapan_interaksi'">
      <IntakeType8Persiapan />
    </div>
    <div v-else-if="tipe == 'intaketype8' && tipe_pengerjaan == 'interaksi'">
      <IntakeType8Interaksi />
    </div>
    <div v-else-if="tipe == 'intaketype9' && tipe_pengerjaan == 'persiapan'">
      <IntakeType9Persiapan />
    </div>
    <div v-else-if="tipe == 'intaketype9' && tipe_pengerjaan == 'presentasi'">
      <IntakeType9Diskusi />
    </div>
  </div>
</template>

<style scoped>
.no-margin {
  padding: 5 !important;
  background-color: rgb(243, 243, 243);
  border-color: aqua;
  border: 1px;
}

.card-header {
  border: 2px solid rgb(255, 255, 255);
  /* Customize the border color and other border properties */
}
</style>
