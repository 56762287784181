<script>
import appConfig from "@/app.config";
import VueCountdown from "@chenfengyuan/vue-countdown";
import axios from "axios";
import Swal from "sweetalert2";
import Editor from "@tinymce/tinymce-vue";
import moment from "moment";

export default {
  page: {
    title: "Pengerjaan Tes Tipe 7 Diskusi LGD",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    VueCountdown,
    editor: Editor,
  },
  data() {
    return {
      id_jadwal: null,
      id_jadwal_detail: null,
      tipe: null,
      tipe_pengerjaan: null,

      txt_materi: "",
      txt_simulasi: "",
      txt_link_zoom: null,
      jumlah_jawaban: "",

      jadwal_data: null,

      soalData: null,
      catatanLgd: null,
      last_update_soal: "-",
      editorData: "",

      waktu_mulai: 0,
      sisa_waktu: 10800000,
    };
  },
  mounted() {
    let self = this;
    self.id_jadwal = self.$route.params.id_jadwal;
    self.id_jadwal_detail = self.$route.params.id_jadwal_detail;
    self.tipe = self.$route.params.tipe;
    self.tipe_pengerjaan = self.$route.params.tipe_pengerjaan;
    self.getJadwalData();
  },
  methods: {
    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },
    getJadwalData() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Silakan klik button petunjuk untuk memulai pengerjaan",
        showConfirmButton: false,
      });

      var url = process.env.VUE_APP_BACKEND_URL_VERSION + "jawaban/jawaban-jadwal-data";
      var config = {
        method: "get",
        url: url,
        params: {
          id_jadwal_detail: self.id_jadwal_detail,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            self.jadwal_data = response_data.data;
            self.txt_link_zoom = response_data.data.jadwal_batch.link_zoom;
            this.getDataDurasi();
            this.getInfoSimulasi();
            this.fetchSoal();
            Swal.close();
          } else {
            Swal.close();
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    getDataDurasi() {
      let self = this;
      var url = process.env.VUE_APP_BACKEND_URL_VERSION + "jawaban/get-durasi-data";
      var config = {
        method: "post",
        url: url,
        data: {
          id_jadwal_batch: self.jadwal_data.jadwal_batch.id_jadwal_batch,
          id_jadwal_detail: self.jadwal_data.jadwal_detail.id,
          tipe: self.tipe,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };
      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            self.waktu_mulai = 10800000;
            var is_combo = self.jadwal_data.jadwal_detail.jadwal_new.j_is_combo;
            console.log(is_combo);
            if (response_data.data == 0) {
              Swal.fire({
                icon: "warning",
                title: "Warning",
                text: "Waktu Pengerjaan Selesai, Menuju ke halaman Kuesioner.",
              });
              /*
              if (is_combo == "NO") {
                this.$router.push({
                  name: "kuesioner",
                  params: {
                    id_jadwal: self.id_jadwal,
                    id_simulasi:
                      self.jadwal_data.jadwal_batch.simulasi_jadwal_id,
                    pin: self.jadwal_data.jadwal_detail.pin,
                  },
                });
              } else {
                this.$router.push({
                  name: "kuesioner-combo",
                  params: {
                    id_jadwal: self.id_jadwal,
                    id_simulasi:
                      self.jadwal_data.jadwal_batch.simulasi_jadwal_id,
                    pin: self.jadwal_data.jadwal_detail.pin,
                  },
                });
              }
              */
              this.$router.push({
                name: "kuesioner-combo",
                params: {
                  id_jadwal: self.id_jadwal,
                  id_simulasi: self.jadwal_data.jadwal_batch.simulasi_jadwal_id,
                  pin: self.jadwal_data.jadwal_detail.pin,
                },
              });
            }
          } else {
            Swal.close();
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    getInfoSimulasi() {
      let self = this;
      var url = process.env.VUE_APP_BACKEND_URL_VERSION + "master/simulasi/" + self.jadwal_data.jadwal_detail.simulasi_id;
      var config = {
        method: "get",
        url: url,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config)
        .then((response) => {
          let response_data = response.data;
          self.txt_materi = response_data.data.level;
          self.txt_simulasi = response_data.data.nama_simulasi;
          self.jumlah_jawaban = response_data.data.simulasi_config.jumlah_jawaban;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    handleCountdownProgress(data) {
      let self = this;
      self.sisa_waktu = data.totalMinutes;
      var count_second = data.seconds;
      if (count_second % 30 == 0) {
        // interval 30 seconds.
        self.postAutoHistory();
      }

      if (count_second == 0) {
        self.checkDurasi();
      }
    },
    fetchSoal() {
      let self = this;
      const apiUrl = process.env.VUE_APP_BACKEND_URL_VERSION + `soal/${self.jadwal_data.jadwal_detail.id}/${self.tipe}`; // Replace with your API endpoint URL
      axios
        .get(apiUrl)
        .then(async (response) => {
          var responseData = response.data.data.data;
          self.soalData = responseData.jawaban_dac_tujuan.jawaban;
          self.catatanLgd = responseData.jawaban_dac.catatan_lgd;
          await this.getDataJawabanTerakhir();
          await this.postDataSoalTrigger();
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    async getDataJawabanTerakhir() {
      let self = this;
      let data_soal_active = { materi_file_id: 0 };
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Silakan klik button petunjuk untuk memulai pengerjaan",
        showConfirmButton: false,
      });

      var url = process.env.VUE_APP_BACKEND_URL_VERSION + "jawaban/jawaban-terakhir";
      var config = {
        method: "post",
        url: url,
        data: {
          id_jadwal: self.id_jadwal,
          id_jadwal_detail: self.id_jadwal_detail,
          tipe: self.tipe,
          tipe_pengerjaan: self.tipe_pengerjaan,
          sisa_waktu: self.sisa_waktu,
          nip: self.jadwal_data.jadwal_detail.nip_asesi,
          pin: self.jadwal_data.jadwal_detail.pin,
          soal_data: JSON.stringify(data_soal_active),
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };

      await axios(config)
        .then((response) => {
          let response_data = response.data.data;
          if (response_data.jawaban == "" || response_data.jawaban == null) {
            self.editorData = "<h3>Masukkan Jawaban Anda Disini.</h3>";
          } else {
            self.editorData = response_data.jawaban;
          }
          Swal.close();
        })
        .catch((errors) => {
          console.log(errors);
          Swal.close();
        });
    },
    async postDataSoalTrigger() {
      let self = this;
      var soal_data = {
        materi_file_id: 0,
        jawaban: self.soalData,
        catatan_lgd: self.catatanLgd,
      };
      var url = process.env.VUE_APP_BACKEND_URL_VERSION + "jawaban/store-soal-trigger";
      var config = {
        method: "post",
        url: url,
        data: {
          id_jadwal: self.id_jadwal,
          id_jadwal_detail: self.id_jadwal_detail,
          tipe: self.tipe,
          tipe_pengerjaan: self.tipe_pengerjaan,
          sisa_waktu: self.sisa_waktu,
          nip: self.jadwal_data.jadwal_detail.nip_asesi,
          pin: self.jadwal_data.jadwal_detail.pin,
          jawaban: self.editorData,
          soal_data: JSON.stringify(soal_data),
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };
      await axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            self.editorData = response_data.data.jawaban_history;
            self.last_update_soal = response_data.data.last_update;
            console.log("berhasil simpan ke Jawaban History");
          } else {
            console.log(response_data.data.message);
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    postAutoHistory() {
      let self = this;
      var soal_data = {
        materi_file_id: 0,
        jawaban: self.soalData,
        catatan_lgd: self.catatanLgd,
      };
      var url = process.env.VUE_APP_BACKEND_URL_VERSION + "jawaban/store-auto-history-data";
      var config = {
        method: "post",
        url: url,
        data: {
          id_jadwal: self.id_jadwal,
          id_jadwal_detail: self.id_jadwal_detail,
          tipe: self.tipe,
          tipe_pengerjaan: self.tipe_pengerjaan,
          sisa_waktu: self.sisa_waktu,
          nip: self.jadwal_data.jadwal_detail.nip_asesi,
          pin: self.jadwal_data.jadwal_detail.pin,
          jawaban: self.editorData,
          soal_data: JSON.stringify(soal_data),
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };
      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            console.log("berhasil simpan ke Jawaban History");
          } else {
            console.log(response_data.data.message);
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    postAnswer() {
      let self = this;
      var soal_data = {
        materi_file_id: 0,
        jawaban: self.soalData,
        catatan_lgd: self.catatanLgd,
      };
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Silakan klik button petunjuk untuk memulai pengerjaan",
        showConfirmButton: false,
      });

      var url = process.env.VUE_APP_BACKEND_URL_VERSION + "jawaban/store";
      var config = {
        method: "post",
        url: url,
        data: {
          id_jadwal: self.id_jadwal,
          id_jadwal_detail: self.id_jadwal_detail,
          tipe: self.tipe,
          tipe_pengerjaan: self.tipe_pengerjaan,
          sisa_waktu: self.sisa_waktu,
          nip: self.jadwal_data.jadwal_detail.nip_asesi,
          pin: self.jadwal_data.jadwal_detail.pin,
          jawaban: self.editorData,
          soal_data: JSON.stringify(soal_data),
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };
      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            Swal.close();
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Berhasil simpan Jawaban.",
            });
          } else {
            console.log(response_data.data.message);
            Swal.close();
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errors,
          });
        });
    },
    checkDurasi() {
      let self = this;
      if (self.sisa_waktu == 0) {
        self.submitTestDone();
      }
    },
    submitTestDone() {
      let self = this;
      self.postAnswer();
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Silakan klik button petunjuk untuk memulai pengerjaan",
        showConfirmButton: false,
      });

      var url = process.env.VUE_APP_BACKEND_URL_VERSION + "jawaban/submit-test-done";
      var config = {
        method: "post",
        url: url,
        data: {
          id_jadwal_detail: self.id_jadwal_detail,
          sisa_waktu: self.sisa_waktu,
          tipe: self.tipe,
          tipe_pengerjaan: self.tipe_pengerjaan,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            Swal.close();
            var is_combo = self.jadwal_data.jadwal_detail.jadwal_new.j_is_combo;
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Pengerjaan Selesai, Menuju ke halaman Kuesioner.",
            });
            if (is_combo == "NO") {
              this.$router.push({
                name: "kuesioner",
                params: {
                  id_jadwal: self.id_jadwal,
                  id_simulasi: self.jadwal_data.jadwal_batch.simulasi_jadwal_id,
                  pin: self.jadwal_data.jadwal_detail.pin,
                },
              });
            } else {
              this.$router.push({
                name: "kuesioner-combo",
                params: {
                  id_jadwal: self.id_jadwal,
                  id_simulasi: self.jadwal_data.jadwal_batch.simulasi_jadwal_id,
                  pin: self.jadwal_data.jadwal_detail.pin,
                },
              });
            }
          } else {
            console.log(response_data.data.message);
            Swal.close();
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errors,
          });
        });
    },
    halamanDetailJadwal() {
      this.$router.push({
        name: "detail-jadwal",
        params: {
          id: this.$route.params.id_jadwal,
        },
      });
    },
  },
};
</script>
<template>
  <div class="wrapper-jawaban">
    <div class="row">
      <div class="col-6">
        <button class="btn btn-warning mt-2 btn-sm" @click="halamanDetailJadwal()"><i class="fa fa-arrow-left"></i> Kembali</button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-6">
            <div class="judul" v-if="txt_materi && txt_simulasi">
              <h4>{{ txt_materi }} - Materi {{ txt_simulasi }}</h4>
            </div>
          </div>
          <div class="col-6">
            <div class="text-end">
              <span class="h4" style="display: none">
                Sisa Waktu
                <span id="timer">
                  <vue-countdown :time="waktu_mulai" v-slot="{ hours, minutes, seconds }" @progress="handleCountdownProgress"> {{ hours }} : {{ minutes }} : {{ seconds }} </vue-countdown>
                </span>
              </span>
              <button class="btn btn-success ms-4 btn-md" v-on:click="submitTestDone"><i class="fa fa-check"></i> Selesai Mengerjakan</button>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-4">
            <div class="card mb-2">
              <div class="card-header d-flex justify-content-between align-items-center no-margin">
                <span class="h4">Materi</span>
              </div>
              <div class="card-body mt-2">
                <div v-if="soalData">
                  <editor
                    api-key="m1l04baz8dzyto2x8wz86rypvdo0ev13809mfj6r4a2pvcdi"
                    :init="{
                      height: 300,
                      menubar: false,
                      plugins: [],
                      toolbar: '',
                      contextmenu_never_use_native: true,
                    }"
                    v-model="soalData"
                  />
                </div>
                <div v-else>Mohon klik tombol Petunjuk & Soal.</div>
                <hr />
                Link Zoom : {{ txt_link_zoom != null ? txt_link_zoom : "-" }}
              </div>
            </div>
            <div class="card">
              <div class="card-header d-flex justify-content-between align-items-center no-margin">
                <span class="h4">Catatan Persiapan</span>
                <span class="ms-2 font-weight-semibold"
                  >Lihat Petunjuk Pengerjaan dengan
                  <a href="https://docs.google.com/document/d/e/2PACX-1vT_AhKadMz0cAQ5V387qFDyqFjnZTgP2GJMG9HyDFuiWk4Ep5-bDs7_22LA2prDIaBeU1lgGWdPrGYO/pub" target="_blank">Klik Disini</a></span
                >
              </div>
              <div class="card-body mt-2">
                <div v-if="catatanLgd">
                  <editor
                    api-key="m1l04baz8dzyto2x8wz86rypvdo0ev13809mfj6r4a2pvcdi"
                    :init="{
                      height: 300,
                      menubar: false,
                      plugins: [],
                      toolbar: '',
                      contextmenu_never_use_native: true,
                    }"
                    v-model="catatanLgd"
                  />
                </div>
                <div v-else>Mohon klik tombol Petunjuk & Soal.</div>
              </div>
            </div>
          </div>
          <div class="col-8">
            <div class="card">
              <div class="card-header d-flex justify-content-between align-items-center no-margin">
                <span class="h4">Papan Diskusi Asesi</span>
              </div>
              <div class="card-body mt-2">
                <editor
                  api-key="m1l04baz8dzyto2x8wz86rypvdo0ev13809mfj6r4a2pvcdi"
                  :init="{
                    height: 700,
                    menubar: true,
                    plugins: ['table'],
                    paste_preprocess: function (plugin, args) {
                      args.content = '';
                    },
                    toolbar:
                      'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                  }"
                  v-model="editorData"
                />
                <div v-show="soalData != null">
                  <p class="mt-2">
                    Last Update :
                    {{ last_update_soal == "-" ? "-" : fullDateTimeFormat(last_update_soal) }}
                  </p>
                  <div class="row">
                    <button class="btn btn-md btn-primary" v-on:click="postAnswer()"><i class="fa fa-save"></i> Simpan</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.no-margin {
  padding: 5 !important;
  background-color: rgb(243, 243, 243);
  border-color: aqua;
  border: 1px;
}

.card-header {
  border: 2px solid rgb(255, 255, 255);
  /* Customize the border color and other border properties */
}
.wrapper-jawaban {
  padding: 30px;
  /* Customize the border color and other border properties */
}
</style>
